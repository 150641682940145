<template>
    <front-page v-if="!$store.state.auth.status.authorized"/>
    <site-list v-else/>
</template>
<script>
    import FrontPage from "./partials/frontPage";
    import SiteList from "./partials/siteList";
    export default {
        name: 'Home',
        components: {SiteList, FrontPage},
        data() {
            return {}
        },
        methods: {},
        computed: {},
        mounted() {
        }
    }
</script>
